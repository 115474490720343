import React, { ReactNode, useRef } from 'react';
import { Button, CircularProgress } from '@mui/material';

interface FileInputButtonProps {
  buttonText: string;
  onFileSelected: (file: File) => unknown;
  disabled?: boolean;
  loading?: boolean;
  accept?: string;
  icon?: ReactNode;
}

export function FileInputButton({
  buttonText,
  onFileSelected,
  disabled,
  loading,
  accept,
  icon
}: FileInputButtonProps) {
  const fileInput = useRef<HTMLInputElement>(null);

  const handleFileSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.files?.length && onFileSelected(e.target.files[0]);
    if (fileInput.current) fileInput.current.value = '';
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => fileInput.current?.click()}
        disabled={disabled || loading}
        startIcon={
          loading ? <CircularProgress size={20} color="inherit" /> : icon
        }
      >
        {buttonText}
      </Button>
      <input
        ref={fileInput}
        type="file"
        onChange={handleFileSelected}
        style={{ display: 'none' }}
        accept={accept}
      />
    </>
  );
}
