import styled from '@emotion/styled';
import { Box, Paper } from '@mui/material';

export const RecommendationBar = styled(Box)`
  background: linear-gradient(
    to right,
    #ff0000 0%,
    #ff7700 15%,
    #ffdd6f 30%,
    #bcff6a 40%,
    #6aff6a 50%,
    #bcff6a 60%,
    #ffdd6f 70%,
    #ff7700 85%,
    #ff0000 100%
  );
  position: relative;
  border: 1px solid #f0f0f0;
  width: 100%;
  height: 20px;
`;

export const RecommendationTab = styled(Box)<{ delta: number }>`
  position: absolute;
  top: -2px;
  left: calc(50% - 4px + ${p => p.delta * 50}%);
  width: 8px;
  height: calc(100% + 4px);
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
`;

export const ChartContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 100%;
  height: 200px;
  margin: 0 auto 20px 0;
  position: relative;
`;

export const TallChartContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 100%;
  height: 400px;
  margin: 0 auto 40px 0;
  position: relative;
`;

export const Panel = styled(Paper)`
  padding: ${p => p.theme.spacing(2)};
`;

export const SelectWrapper = styled.div`
  flex: 0 0 240px;
`;

export const DataContainer = styled(Box)`
  position: relative;
  height: calc(100vh - 380px);
  overflow-y: auto;
  padding: ${p => p.theme.spacing(1)};
  overflow-x: hidden;
`;
