import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Alert,
  Link,
  MenuItem,
  Paper,
  Select,
  Typography,
  IconButton
} from '@mui/material';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useOrdinalColorScale } from '@nivo/colors';
import { ArrowBackRounded as BackIcon } from '@mui/icons-material';

import { compareLabels } from 'helpers';
import { ResponsiveBarCanvas as ResponsiveBar } from '@nivo/bar';
import { ResponsivePie } from '@nivo/pie';
import {
  FormFieldWrapper,
  FormFieldWrapperNarrow,
  FormLabel,
  FormRow,
  FormSection,
  FormSectionTitle,
  FormTextValue,
  Gap,
  ProductInfoSummary
} from '../../components';

import {
  AccuracyStat,
  ProductInfoResponse,
  formatStat,
  useSpot
} from '../../framework';
import { VariantStatistics } from './variant-statistics';

import {
  Panel,
  RecommendationBar,
  RecommendationTab,
  TallChartContainer
} from './common';
import { barMargins, isOrderedSize } from './accuracy-helpers';

interface ProductAccuracyInfoProps {
  productAccuracyInfo: AccuracyStat[];
  onBack: () => unknown;
}

export function ProductAccuracyInfo({
  productAccuracyInfo,
  onBack
}: ProductAccuracyInfoProps) {
  const [selectedProduct, setSelectedProduct] = useState<ProductInfoResponse>();
  const { query, spot } = useSpot();
  const { t } = useTranslation();

  const returnsColorScale = useOrdinalColorScale(
    { scheme: 'category10' },
    'id'
  );
  const colorScale = useOrdinalColorScale({ scheme: 'category10' }, 'id');

  const variantColors = colorScale({ id: 'variants' });

  const [returnColors] = useState<
    Record<'tooSmall' | 'tooBig' | 'wrongSize' | 'otherReturns', string>
  >({
    otherReturns: returnsColorScale({ id: 'otherReturns' }),
    wrongSize: returnsColorScale({ id: 'wrongSize' }),
    tooBig: returnsColorScale({ id: 'tooBig' }),
    tooSmall: returnsColorScale({ id: 'tooSmall' })
  });

  const [selectedVariant, setSelectedVariant] = useState<AccuracyStat>(
    productAccuracyInfo[0]
  );

  const [selectedVariantName, setSelectedVariantName] = useState(
    productAccuracyInfo[0].variant
  );

  const correlationId = productAccuracyInfo?.[0]?.correlationId;

  useEffect(() => {
    setSelectedVariantName(productAccuracyInfo[0].variant);
    setSelectedVariant(productAccuracyInfo[0]);
  }, [
    productAccuracyInfo,
    correlationId,
    setSelectedVariant,
    setSelectedVariantName
  ]);

  const onTabChange = useCallback(
    (v: string) => {
      const foundVariant = productAccuracyInfo.find(p => p.variant === v);
      if (foundVariant) {
        setSelectedVariantName(v);
        setSelectedVariant(foundVariant);
      }
    },
    [setSelectedVariantName, setSelectedVariant, productAccuracyInfo]
  );

  const refreshProduct = useCallback(async () => {
    try {
      await query(
        `product-info/${encodeURIComponent(selectedVariant.correlationId)}`,
        {
          retailer: selectedVariant.shopId
        },
        ['productInfo', selectedVariant.shopId, selectedVariant.correlationId]
      );
      setSelectedProduct(
        spot.data?.productInfo?.[selectedVariant.shopId]?.[
          selectedVariant.correlationId
        ]
      );
    } catch (e) {
      setSelectedProduct(undefined);
    }
  }, [
    spot,
    setSelectedProduct,
    query,
    selectedVariant.correlationId,
    selectedVariant.shopId
  ]);

  const originalProduct =
    spot.data?.productInfo?.[selectedVariant.shopId]?.[
      selectedVariant.correlationId
    ];

  useEffect(() => {
    refreshProduct();
  }, [refreshProduct]);

  const onUpdateProduct = useCallback(
    product => {
      setSelectedProduct({ ...product });
    },
    [setSelectedProduct]
  );

  const brandObj = spot.data?.brands.find(
    b => b.name === selectedProduct?.brand
  );

  const deltas = useMemo(
    () =>
      productAccuracyInfo
        .reduce(
          (accum, variant) => {
            const resultsData = variant.results
              .reduce(
                (accumInner, r) => {
                  const resultName = r.toLocaleUpperCase();
                  let found = accumInner.find(res => res.name === resultName);

                  if (!found) {
                    found = {
                      name: resultName,
                      count: 0,
                      countColor: isOrderedSize(resultName, variant.variant)
                        ? '#ff0000'
                        : '#000000'
                    };
                    accumInner.push(found);
                  }
                  found.count += 1;

                  return accumInner;
                },
                [] as { name: string; count: number; countColor: string }[]
              )
              .sort((a, b) => compareLabels(b.name, a.name));

            const targetIndex = resultsData.findIndex(r =>
              isOrderedSize(r.name, variant.variant)
            );

            let countSum = 0;
            const delta = resultsData?.reduce((accumInner, r, index) => {
              countSum += r.count;
              if (index < targetIndex) {
                return accumInner - r.count;
              }
              if (index > targetIndex) {
                return accumInner + r.count;
              }
              return accumInner;
            }, 0);

            return [
              ...accum,
              {
                delta,
                countSum,
                variant: variant.variant,
                productId: variant.correlationId,
                orders: variant.orders,
                returns: variant.returned,
                tooSmall: variant.tooSmall,
                tooBig: variant.tooBig,
                wrongSize: variant.wrongSize
              }
            ];
          },
          [] as {
            delta: number;
            countSum: number;
            variant: string;
            productId: string;
            tooSmall: number;
            tooBig: number;
            orders: number;
            returns: number;
            wrongSize: number;
          }[]
        )
        .sort((a, b) => compareLabels(b.variant, a.variant)),
    [productAccuracyInfo]
  );

  const delta = deltas.reduce((accum, d) => accum + d.delta, 0);
  const countSum = deltas.reduce((accum, d) => accum + d.countSum, 0);

  const tooSmall = deltas.reduce((accum, d) => accum + d.tooSmall, 0);
  const tooBig = deltas.reduce((accum, d) => accum + d.tooBig, 0);
  const wrongSize = deltas.reduce((accum, d) => accum + d.wrongSize, 0);
  const orders = deltas.reduce((accum, d) => accum + d.orders, 0);
  const returns = deltas.reduce((accum, d) => accum + d.returns, 0);

  const safeDelta = 0.15;

  const overallRecommendationThreshold = 40;
  const variantRecommendationThreshold = 10;

  const returnPercentage = 0.1;

  const adjustUpVariants = deltas
    .map(d =>
      d.countSum >= variantRecommendationThreshold &&
      d.delta / d.countSum > safeDelta
        ? d.variant
        : undefined
    )
    .filter(d => d !== undefined);
  const adjustDownVariants = deltas
    .map(d =>
      d.countSum >= variantRecommendationThreshold &&
      d.delta / d.countSum < -safeDelta
        ? d.variant
        : undefined
    )
    .filter(d => d !== undefined);

  const highReturnVariants = deltas
    .map(d => (d.returns / d.orders > returnPercentage ? d.variant : undefined))
    .filter(d => d !== undefined);

  return (
    <Panel>
      {onBack && (
        <IconButton onClick={onBack} size="large">
          <BackIcon />
        </IconButton>
      )}
      {selectedProduct && (
        <ProductInfoSummary
          product={selectedProduct}
          onRefresh={refreshProduct}
          onUpdateProduct={onUpdateProduct}
          sourceProduct={originalProduct}
        />
      )}
      <Gap />
      <FormSection>
        <FormSectionTitle>{t('overallRecommendations')}</FormSectionTitle>
        {countSum < overallRecommendationThreshold && (
          <Alert severity="info">{t('notEnoughDataForRec')}</Alert>
        )}
        {countSum >= overallRecommendationThreshold && (
          <Alert severity="info">
            {Math.abs(delta / countSum) < safeDelta && t('noAdjustment')}
            {delta / countSum < -safeDelta && t('adjustDown')}
            {delta / countSum > safeDelta && t('adjustUp')}
          </Alert>
        )}
        <Gap />
        {!!adjustUpVariants.length && (
          <Alert severity="info">
            {t('adjustUpVariants')}
            <br />
            {adjustUpVariants.join(', ')}
          </Alert>
        )}
        <Gap />
        {!!adjustDownVariants.length && (
          <Alert severity="info">
            {t('adjustDownVariants')}
            <br />
            {adjustDownVariants.join(', ')}
          </Alert>
        )}
        <Gap />
        {returns / countSum > returnPercentage && (
          <Alert severity="info">
            {t('highReturnRate', {
              returnRate: formatStat((returns / orders) * 100, true, 2)
            })}
          </Alert>
        )}
        <Gap />
        {!!highReturnVariants.length && (
          <Alert severity="info">
            {t('highReturnRateVariant')}
            <br />
            {highReturnVariants.join(', ')}
          </Alert>
        )}
        <Gap />
        <RecommendationBar>
          <RecommendationTab delta={delta / countSum} title={`${delta}`} />
        </RecommendationBar>
        <Gap />
      </FormSection>
      <Gap />
      <TallChartContainer>
        <Typography variant="h5" color="primary">
          {t('variantDeltas')}
        </Typography>
        <ResponsiveBar
          data={deltas.map(d => ({
            id: `${d.productId}-${d.variant}`,
            name: d.variant,
            value: (d.delta / d.countSum) * 100,
            delta: d.delta,
            countSum: d.countSum
          }))}
          colors={val =>
            (val.data.countSum as number) < variantRecommendationThreshold
              ? '#ddd'
              : variantColors
          }
          labelTextColor="#fff"
          tooltipLabel={val =>
            `${t('label')}: ${val.data.name as string}
${t('deviatedRecommendations', { count: val.data.delta as number })}
${t('totalRecommendations', { count: val.data.countSum as number })}
${t('value')}`
          }
          valueFormat={val => formatStat(val, true, 0)}
          enableLabel={false}
          keys={['value']}
          groupMode="grouped"
          indexBy="name"
          padding={0.3}
          margin={{ ...barMargins, bottom: 100 }}
          minValue={-100}
          maxValue={100}
          axisBottom={{
            tickRotation: -45
          }}
        />
      </TallChartContainer>
      {returns > 0 && (
        <TallChartContainer>
          <Typography variant="h5" color="primary">
            {t('returns')}
          </Typography>
          <Typography variant="subtitle2" color="primary">
            {t('returnsGraphDescription')}
          </Typography>
          <ResponsivePie
            data={[
              {
                label: 'otherReturns',
                id: t('otherReturns'),
                value: returns - wrongSize - tooBig - tooSmall
              },
              {
                label: 'tooSmall',
                id: t('tooSmall'),
                value: tooSmall
              },
              {
                label: 'tooBig',
                id: t('tooBig'),
                value: tooBig
              },
              {
                label: 'wrongSize',
                id: t('wrongSize'),
                value: wrongSize,
                color: '#ff00ff'
              }
            ].filter(d => d.value > 0)}
            innerRadius={0.4}
            padAngle={3}
            cornerRadius={8}
            activeOuterRadiusOffset={8}
            valueFormat={val => formatStat(val, false, 0)}
            colors={val => returnColors[val.label]}
            arcLabelsTextColor="#fff"
            arcLabelsSkipAngle={10}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor="#333333"
            arcLinkLabelsThickness={3}
            arcLinkLabelsDiagonalLength={8}
            arcLinkLabelsStraightLength={32}
            arcLinkLabelsColor={{ from: 'color' }}
            margin={barMargins}
            borderWidth={5}
            borderColor="rgba(255,255,255,0)"
          />
        </TallChartContainer>
      )}
      <FormSection>
        <FormRow>
          <FormLabel size="narrow">{t('orders')}</FormLabel>
          <FormFieldWrapper>
            <FormTextValue>{formatStat(orders)}</FormTextValue>
          </FormFieldWrapper>
        </FormRow>
        <FormRow>
          <FormLabel size="narrow">{t('returns')}</FormLabel>
          <FormFieldWrapper>
            <FormTextValue>{formatStat(returns)}</FormTextValue>
          </FormFieldWrapper>
        </FormRow>
        <FormRow>
          <FormLabel size="narrow">{t('returnRate')}</FormLabel>
          <FormFieldWrapper>
            <FormTextValue>
              {formatStat((returns / orders) * 100, true, 2)}
            </FormTextValue>
          </FormFieldWrapper>
        </FormRow>
        <FormRow>
          <FormLabel size="narrow">{t('sizeRelatedReturnRate')}</FormLabel>
          <FormFieldWrapper>
            <FormTextValue>
              {formatStat(((tooBig + tooSmall) / orders) * 100, true, 2)}
            </FormTextValue>
          </FormFieldWrapper>
        </FormRow>
      </FormSection>
      <FormSection>
        <FormSectionTitle>{t('variantStats')}</FormSectionTitle>
        <FormRow>
          <FormLabel size="narrow">{t('variant')}</FormLabel>
          <FormFieldWrapper>
            <Select
              fullWidth
              value={selectedVariantName}
              onChange={e => onTabChange(e.target.value as string)}
            >
              {productAccuracyInfo
                .map(p => p.variant)
                .filter((value, index, self) => self.indexOf(value) === index)
                .map(variant => (
                  <MenuItem key={variant} value={variant}>
                    {variant}
                  </MenuItem>
                ))}
            </Select>
          </FormFieldWrapper>
        </FormRow>
        <FormRow>
          <FormLabel size="narrow">{t('users')}</FormLabel>
          <FormFieldWrapperNarrow>
            <Typography>{selectedVariant.users}</Typography>
          </FormFieldWrapperNarrow>
        </FormRow>
        {selectedProduct?.tagProductName &&
          selectedProduct?.tagGender &&
          brandObj && (
            <FormRow>
              <FormLabel size="narrow">{t('tag')}</FormLabel>
              <FormFieldWrapperNarrow>
                <Link
                  href={`/size-and-fit/size-charts/${
                    brandObj.slug
                  }/${encodeURIComponent(brandObj.name)}-${
                    selectedProduct?.tagProductName
                  }-${selectedProduct?.tagGender}`}
                >
                  {selectedProduct?.tagName}
                </Link>
              </FormFieldWrapperNarrow>
            </FormRow>
          )}
        <FormRow>
          <FormLabel size="narrow">{t('productGender')}</FormLabel>
          <FormFieldWrapperNarrow>
            <Typography>
              {t(selectedVariant.productGender?.toLocaleLowerCase())}
            </Typography>
          </FormFieldWrapperNarrow>
        </FormRow>
        <FormRow>
          <FormLabel size="narrow">{t('errors')}</FormLabel>
          <FormFieldWrapperNarrow>
            <Typography>{selectedVariant.sizingErrors}</Typography>
          </FormFieldWrapperNarrow>
        </FormRow>
      </FormSection>

      <VariantStatistics variant={selectedVariant} />
      <Gap />
    </Panel>
  );
}
