import React, { MouseEvent, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { Box, Button, Hidden } from '@mui/material';
import styled from '@emotion/styled';

import { openInNewTab } from 'framework';
import { SvgIconComponent } from '@mui/icons-material';

type ColorVariant = 'contrast' | 'primary';

const ContrastButton = styled(Button)<{
  colorvariant: ColorVariant;
  active: string;
}>`
  color: ${p =>
    p.colorvariant === 'contrast'
      ? p.theme.palette.primary.contrastText
      : p.theme.palette.primary.main};
  height: 64px;
  text-decoration: ${p => (p.active === 'true' ? 'underline' : 'unset')};
  padding: ${p => p.theme.spacing(0, 2)};
  justify-content: flex-start;

  .MuiButton-startIcon {
    margin-left: 0;
  }
`;

const LinkBox = styled(Box)<{ active: string }>`
  background-color: ${p =>
    p.active === 'true' ? 'rgba(134, 132, 116, 0.1)' : 'unset'};
  max-width: 240px;
  border-radius: 4px;
`;

export function LinkButton({
  title,
  path,
  icon: Icon,
  variant = 'primary'
}: {
  title?: string;
  path: string;
  icon: SvgIconComponent | (() => JSX.Element);
  variant?: ColorVariant;
}) {
  const navigate = useNavigate();
  const location = useLocation();

  const goTo = useCallback(
    (event: MouseEvent) => {
      if (
        path.startsWith('http') ||
        path.startsWith('mailto') ||
        event.ctrlKey
      ) {
        openInNewTab(path);
      } else {
        navigate(path);
      }
    },
    [path, navigate]
  );

  const active = String(location.pathname.startsWith(path));

  return (
    <LinkBox active={active}>
      <ContrastButton
        variant="text"
        onClick={goTo}
        startIcon={!!Icon && <Icon />}
        colorvariant={variant}
        fullWidth
        active={active}
      >
        <Hidden xlDown>{title}</Hidden>
      </ContrastButton>
    </LinkBox>
  );
}
