import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Autocomplete,
  Button,
  CircularProgress,
  TextField
} from '@mui/material';
import {
  AddRounded as NewIcon,
  SaveRounded as SaveIcon
} from '@mui/icons-material';
import {
  isNotNullOrUndefined,
  useSpot,
  DictionaryEntryType
} from '../../framework';
import { CenteredRow, FormSection, Gap } from '../../components';
import { ScrollableContainer, SearchContainer } from './common';
import { ProductRow } from './product-row';

export function ProductDictionary({
  loading,
  productWords,
  setProductWords,
  saveDictionary
}: {
  loading: boolean;
  productWords: {
    word: string;
    product: string;
    key: string;
    type?: DictionaryEntryType;
  }[];
  setProductWords: React.Dispatch<
    React.SetStateAction<
      {
        word: string;
        product: string;
        key: string;
        type?: DictionaryEntryType;
      }[]
    >
  >;
  saveDictionary: () => unknown;
}) {
  const { t } = useTranslation();
  const [productFilter, setProductFilter] = useState<string | null>(null);
  const [dragIndex, setDragIndex] = useState<number | null>(null);
  const [dragTargetIndex, setDragTargetIndex] = useState<number | null>(null);

  const { spot } = useSpot();

  const firstProduct = spot.data.products?.[0]?.name ?? 'top';

  const addProductWord = useCallback(() => {
    setProductFilter(null);
    setProductWords(oldWords => [
      {
        word: 'word',
        product: firstProduct,
        key: `${oldWords.length}-new-word`
      },
      ...oldWords
    ]);
  }, [setProductWords, firstProduct]);

  const updateProductWord = useCallback(
    (index, word, product, type) => {
      setProductWords(oldWords => {
        const newProductWords = [...oldWords];
        newProductWords[index] = {
          word,
          product,
          type,
          key: newProductWords[index].key
        };

        return [...newProductWords];
      });
    },
    [setProductWords]
  );

  const removeProductWord = useCallback(
    index => {
      setProductWords(oldWords => {
        const newWords = [...oldWords];
        newWords.splice(index, 1);
        return [...newWords];
      });
    },
    [setProductWords]
  );

  const productSearchOnChange = useCallback(
    (_, value) => {
      setProductFilter(value);
    },
    [setProductFilter]
  );

  const productSearchInput = useCallback(
    p => (
      <TextField
        {...p}
        margin="none"
        placeholder={t('searchProductWords')}
        variant="outlined"
      />
    ),
    [t]
  );

  const onDragStart = useCallback(index => {
    setDragIndex(index);
    setDragTargetIndex(null);
  }, []);

  const onDragOver = useCallback(index => {
    setDragTargetIndex(index);
  }, []);

  const onDragEnd = useCallback(() => {
    const validDrag =
      isNotNullOrUndefined(dragIndex) && isNotNullOrUndefined(dragTargetIndex);
    if (!validDrag) {
      return;
    }

    setProductWords(oldWorlds => {
      const newWords = [...oldWorlds];
      const word = newWords[dragIndex];
      const deleteIndex =
        dragIndex < dragTargetIndex ? dragIndex : dragIndex + 1;
      newWords.splice(dragTargetIndex, 0, word);
      newWords.splice(deleteIndex, 1);
      return newWords;
    });
    setDragIndex(null);
    setDragTargetIndex(null);
  }, [setDragIndex, dragIndex, dragTargetIndex, setProductWords]);

  const productSearchOptions = useMemo(
    () =>
      productWords.reduce(
        (accum, curr) =>
          [...accum, curr.word, curr.product].filter(
            (v, index, arr) => arr.indexOf(v) === index
          ),
        [] as string[]
      ),
    [productWords]
  );

  const productRows = productWords
    .filter(({ word, product }) =>
      productFilter
        ? word.indexOf(productFilter) !== -1 ||
          product.indexOf(productFilter) !== -1
        : true
    )
    .map(({ word, product, type, key }) => (
      <ProductRow
        key={key}
        word={word}
        product={product}
        type={type}
        index={productWords.findIndex(w => w.key === key)}
        updateProductWord={updateProductWord}
        removeProductWord={removeProductWord}
        loading={loading}
        dragStart={onDragStart}
        dragOver={onDragOver}
        dragEnd={onDragEnd}
      />
    ));

  return (
    <FormSection>
      <CenteredRow>
        <SearchContainer>
          <Autocomplete
            fullWidth
            onChange={productSearchOnChange}
            value={productFilter}
            renderInput={productSearchInput}
            options={productSearchOptions}
          />
        </SearchContainer>
        <Gap />
        <Button
          variant="contained"
          color="primary"
          disabled={loading}
          onClick={addProductWord}
          startIcon={loading ? <CircularProgress size={20} /> : <NewIcon />}
        >
          {t(`newWord`)}
        </Button>
        <Gap />
        <Button
          variant="contained"
          color="primary"
          disabled={loading}
          onClick={saveDictionary}
          startIcon={loading ? <CircularProgress size={20} /> : <SaveIcon />}
        >
          {t(`save`)}
        </Button>
      </CenteredRow>
      <ScrollableContainer>{productRows}</ScrollableContainer>
    </FormSection>
  );
}
