import React, { useCallback, useEffect } from 'react';
import { Box, Button, Checkbox, Typography } from '@mui/material';
import { DataGrid, GridCellParams, GridColumns } from '@mui/x-data-grid';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import {
  RetailersPopdownList,
  HorizontalLoadingBar,
  CenteredRow,
  Spacer,
  Line
} from 'components';
import { Experiment, useSpot } from 'framework';
import { AddCircleRounded as AddIcon } from '@mui/icons-material';

export function ExperimentList() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { query, loading, data } = useSpot();

  useEffect(() => {
    (async () => query('experiments', {}, ['experiments']))();
  }, [query]);

  const experimentHeaders: GridColumns = [
    {
      field: 'active',
      headerName: t('active'),
      flex: 1,
      resizable: false,
      cellClassName: 'grid-select',
      renderCell: (params: GridCellParams) => (
        <Checkbox checked={!!params.value} />
      )
    },
    {
      field: 'name',
      headerName: t('name'),
      width: 250,
      flex: 1,
      resizable: false,
      cellClassName: 'grid-select'
    },
    {
      field: 'userPercentage',
      headerName: t('userPercentage'),
      flex: 1,
      resizable: false,
      cellClassName: 'grid-select',
      renderCell: (params: GridCellParams) => {
        if (params.value) {
          return `${params.value}%`;
        }
        return <>&nbsp;</>;
      }
    },
    {
      field: 'variants',
      headerName: t('variantCount'),
      flex: 1,
      resizable: false,
      cellClassName: 'grid-select',
      renderCell: (params: GridCellParams) =>
        params.value ? (params.value as unknown[]).length : <>&nbsp;</>
    },
    {
      field: 'retailers',
      headerName: t('brandRetailersCount'),
      width: 200,
      flex: 1,
      resizable: false,
      sortable: false,
      align: 'left',
      renderCell: (params: GridCellParams) => {
        return (
          <RetailersPopdownList
            id={params.id}
            retailers={params.value as []}
            includeAllRetailers
          />
        );
      }
    }
  ];

  const createExperiment = useCallback(() => {
    navigate(`/experiments/new`);
  }, [navigate]);

  const gridCellClicked = useCallback(
    (params: GridCellParams) => {
      const clickedField = params.colDef.field;
      if (
        !['active', 'name', 'userPercentage', 'variants'].includes(clickedField)
      ) {
        return;
      }
      if (params.row) {
        const selected = params.row as unknown as Experiment;
        navigate(`/experiments/${selected.id}`);
      }
    },
    [navigate]
  );

  const sortedExperiments =
    data.experiments?.slice().sort((exp1, exp2) => {
      if (!exp1.active) return 1;
      if (!exp2.active) return -1;
      return exp2.id - exp1.id;
    }) ?? [];

  return (
    <>
      <CenteredRow>
        <Typography variant="h4">{t('experiments')}</Typography>
        <Spacer />
        <Button
          variant="contained"
          color="primary"
          onClick={createExperiment}
          startIcon={<AddIcon />}
        >
          {t('addExperiment')}
        </Button>
      </CenteredRow>
      <Line />
      <HorizontalLoadingBar loading={loading} />
      <Box style={{ display: 'flex', height: '58vh', width: '100%' }}>
        <DataGrid
          onCellClick={gridCellClicked}
          disableColumnMenu
          disableColumnSelector
          disableSelectionOnClick
          loading={loading}
          rows={sortedExperiments}
          columns={experimentHeaders}
          autoPageSize
          columnBuffer={2}
          headerHeight={40}
          rowHeight={52}
        />
      </Box>
    </>
  );
}
