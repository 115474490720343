import React, { ReactNode } from 'react';
import { Box, Container, Hidden, Link, Typography } from '@mui/material';
import styled from '@emotion/styled';

import { Gap, Spacer } from 'components';

const ScrollContainer = styled(Box)`
  overflow: auto;
  position: relative;
  margin-top: 52px;
  display: flex;
  flex-direction: column;
  height: calc(100% - 52px);
  flex: 0 1 1536px;
`;

const StyledContent = styled(Container)`
  background: ${p => p.theme.palette.background.paper};
  border-radius: 4px;
  box-shadow: ${p => p.theme.shadows[1]};
  flex: 0;

  padding: ${p => p.theme.spacing(2, 2)};
  ${p => p.theme.breakpoints.down('md')} {
    padding: ${p => p.theme.spacing(2, 1)};
  }
`;

const Footer = styled(Typography)`
  width: 100%;
  padding: ${p => p.theme.spacing(1, 0)};
  background-color: ${p => p.theme.palette.background.default};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

interface Props {
  children: ReactNode;
  ignoreContainer?: boolean;
}

export function Page({ children, ignoreContainer }: Props) {
  const year = new Date().getFullYear();
  const MainContainer = ignoreContainer ? Box : ScrollContainer;
  const PageContainer = ignoreContainer ? Box : StyledContent;
  return (
    <MainContainer>
      {!ignoreContainer && (
        <Hidden lgDown>
          <Gap size={2} />
        </Hidden>
      )}
      <PageContainer disableGutters maxWidth="xl">
        {children ?? null}
      </PageContainer>
      <Spacer />
      <Footer variant="caption" align="center">
        <Typography variant="caption">
          <Link href="https://faslet.me" target="_blank">
            {`© ${year} Faslet `}
          </Link>
        </Typography>
        <Gap size={2} />
      </Footer>
    </MainContainer>
  );
}
