import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Autocomplete,
  Button,
  CircularProgress,
  IconButton,
  TextField,
  Typography
} from '@mui/material';
import {
  DeleteForeverRounded as DeleteIcon,
  SaveRounded as SaveIcon,
  AddCircleRounded as AddIcon
} from '@mui/icons-material';
import { useErrorNotification, useSpot } from '../../framework';
import {
  CenteredRow,
  FormFieldWrapperWide,
  FormRow,
  FormSection,
  FormSectionTitle,
  FormTextInput,
  Gap
} from '../../components';
import { ScrollableContainer, SearchContainer } from './common';

export function IgnoreList({
  onLoadingChanged
}: {
  onLoadingChanged: (loading: boolean) => unknown;
}) {
  const { t } = useTranslation();
  const { spot, query, command, loading } = useSpot();
  const { displayErrors, notification } = useErrorNotification();
  const [filter, setFilter] = useState<string | null>(null);

  const [ignoreList, setIgnoreList] = useState<string[]>([]);

  useEffect(() => {
    onLoadingChanged(loading);
  }, [loading, onLoadingChanged]);

  const refresh = useCallback(async () => {
    await query('product-predictor/ignored-words', {}, [
      'productPredictor',
      'ignoreList'
    ]);

    setIgnoreList([...(spot.data.productPredictor?.ignoreList?.words ?? [])]);
  }, [query, spot, setIgnoreList]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  const saveIgnoreList = useCallback(async () => {
    try {
      await command('product-predictor/ignored-words', {
        words: [...ignoreList]
      });
      await refresh();
    } catch (e) {
      displayErrors(e as Error[]);
    }
  }, [displayErrors, command, refresh, ignoreList]);

  const onUpdateWord = useCallback(
    (newWord, i) => {
      setIgnoreList(oldList => {
        const newList = [...oldList];
        newList[i] = newWord;
        return newList;
      });
    },
    [setIgnoreList]
  );

  const onRemoveWord = useCallback(
    index => {
      setIgnoreList(oldList => {
        const newList = [...oldList];
        newList.splice(index, 1);
        return newList;
      });
    },
    [setIgnoreList]
  );

  const onAddWord = useCallback(() => {
    setFilter(null);
    setIgnoreList(oldList => ['', ...oldList]);
  }, [setIgnoreList]);

  const onSearchChange = useCallback(
    (_, value) => {
      setFilter(value);
    },
    [setFilter]
  );

  const searchInput = useCallback(
    p => (
      <TextField
        {...p}
        margin="none"
        placeholder={t('searchWords')}
        variant="outlined"
      />
    ),
    [t]
  );

  const searchOptions = useMemo(
    () =>
      ignoreList.reduce(
        (accum, curr) =>
          [...accum, curr].filter((v, index, arr) => arr.indexOf(v) === index),
        [] as string[]
      ),
    [ignoreList]
  );

  const filteredWords = useMemo(
    () =>
      ignoreList.filter(word => (filter ? word.indexOf(filter) !== -1 : true)),
    [ignoreList, filter]
  );

  return (
    <>
      <CenteredRow>
        <FormSectionTitle>
          {`${t('ignoreList')} (${ignoreList.length})`}
        </FormSectionTitle>
        <Gap />
        <SearchContainer>
          <Autocomplete
            fullWidth
            onChange={onSearchChange}
            value={filter}
            renderInput={searchInput}
            options={searchOptions}
          />
        </SearchContainer>
        <Gap />
        <IconButton onClick={onAddWord} color="primary" size="large">
          <AddIcon />
        </IconButton>
        <Gap />
        <Button
          variant="contained"
          color="primary"
          disabled={loading}
          onClick={saveIgnoreList}
          startIcon={loading ? <CircularProgress size={20} /> : <SaveIcon />}
        >
          {t(`save`)}
        </Button>
      </CenteredRow>
      <ScrollableContainer>
        {filteredWords.map(word => (
          <IgnoreItem
            key={word}
            loading={loading}
            index={ignoreList.indexOf(word)}
            word={word}
            updateWord={onUpdateWord}
            removeWord={onRemoveWord}
          />
        ))}
      </ScrollableContainer>
      <FormSection>
        <Typography variant="caption">{t('ignoreListInfo')}</Typography>
      </FormSection>
      {notification}
    </>
  );
}

function IgnoreItem({
  loading,
  index,
  word,
  updateWord,
  removeWord
}: {
  loading: boolean;
  index: number;
  word: string;
  updateWord: (word, index) => unknown;
  removeWord: (index) => unknown;
}) {
  const [internalState, setInternalState] = useState<string>(word);
  const timeoutHandle = useRef(-1);

  useEffect(() => {
    if (timeoutHandle.current >= 0) {
      clearTimeout(timeoutHandle.current);
    }
    timeoutHandle.current = window.setTimeout(() => {
      updateWord(internalState, index);
    }, 500);
  }, [internalState, index, timeoutHandle, updateWord]);

  const onUpdateWord = useCallback(
    newWord => {
      setInternalState(newWord);
    },
    [setInternalState]
  );

  const onRemoveWord = useCallback(() => {
    removeWord(index);
  }, [index, removeWord]);

  return (
    <FormRow>
      <FormFieldWrapperWide>
        <FormTextInput
          value={internalState}
          onChange={onUpdateWord}
          disabled={loading}
        />
      </FormFieldWrapperWide>
      <IconButton onClick={onRemoveWord} disabled={loading} size="large">
        <DeleteIcon />
      </IconButton>
    </FormRow>
  );
}
