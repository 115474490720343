import moment from 'moment';

export const defaultNumberFormatOptions: Intl.NumberFormatOptions = {
  maximumFractionDigits: 9
};

export const formatNumber = (
  amount: number,
  options?: Intl.NumberFormatOptions,
  locale?: string
) => {
  const finalOptions: Intl.NumberFormatOptions = {
    ...defaultNumberFormatOptions,
    ...options
  };

  return Number(amount).toLocaleString(locale, finalOptions);
};

export const formatStat = (
  number: number | unknown,
  percent = false,
  decimals: number | undefined = undefined
) =>
  `${
    // eslint-disable-next-line no-nested-ternary
    Number.isNaN(number) || !Number.isFinite(number)
      ? '--'
      : formatNumber(number as number, {
          minimumFractionDigits: decimals,
          maximumFractionDigits: decimals
        })
  }${percent ? '%' : ''}`;

type DateTime = string | moment.Moment | Date;

export const formatTime = (arg?: DateTime) => {
  return moment(arg).format('LTS');
};

export const formatDate = (arg?: DateTime) => {
  return moment(arg).format('LL');
};

export const formatDateTime = (arg?: DateTime, compact = false) => {
  return `${moment(arg).format(compact ? 'll' : 'LL')} ${moment(arg).format(
    compact ? 'LTS' : 'LTS'
  )}`;
};

export const toDisplayName = (camelCaseName: string) =>
  camelCaseName.split(/(?=[A-Z])/).join(' ');
