import React, { useState, useContext, useCallback, useMemo } from 'react';
import styled from '@emotion/styled';

import {
  Alert,
  Box,
  Button,
  IconButton,
  Slider,
  SvgIcon,
  Typography
} from '@mui/material';

import {
  RssFeedRounded as FeedIcon,
  WebRounded as WidgetIcon,
  EditRounded as EditIcon,
  LinkRounded as LinkIcon,
  LinkOffRounded as UnlinkIcon,
  SaveRounded as SaveIcon,
  NotInterestedRounded as ExcludeIcon,
  UnarchiveRounded as SizeChartUploadIcon,
  DeleteForeverRounded as DeleteIcon
} from '@mui/icons-material';

import {
  ProductGender,
  ProductInfoListResponse,
  RetailerContext,
  RoleContext,
  Tag,
  openInNewTab,
  useErrorNotification,
  useSpot
} from 'framework';

import { ReactComponent as PredictIcon } from 'images/wand.svg';
import { useTranslation } from 'react-i18next';
import {
  FormFieldWrapper,
  FormLabel,
  FormRow,
  FormSection,
  FormSectionTitle
} from './form-input';

import { CroppedImage } from './cropped-image';
import { Gap, Spacer } from './spacer';
import { CenteredRow } from './layout';
import {
  ChangeTagDialog,
  ConfirmDialog,
  ConnectProductDialog
} from './dialogs';
import { ConfirmDeleteAlert } from './confirm-delete-alert';
import { SizeChartLink } from './size-chart-link';
import { BrandLink, useBrandLink } from './brand-link';
import { Widget } from './widget';
import { Table } from './table';

const ImageContainer = styled(Box)`
  position: relative;
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 20px;
  cursor: pointer;
`;

const NameContainer = styled(CenteredRow)`
  flex-wrap: nowrap;
`;

const IconContainer = styled(Box)`
  margin-right: ${p => p.theme.spacing(2)};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SizeChartEditContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const ActionsContainer = styled(CenteredRow)`
  margin-top: ${p => p.theme.spacing(1)};
  margin-left: ${p => p.theme.spacing(-1)};
`;

const WidgetSection = styled(FormSection)`
  overflow: visible;
`;

const TtsSkewInfo = styled(Typography)`
  padding: ${p => p.theme.spacing(1)};
  margin-bottom: 30px;
`;

const TagWarningSection = styled(Box)`
  padding: ${p => p.theme.spacing(1)};
  border: 1px solid ${p => p.theme.palette.error.main};
  border-radius: 4px;
`;

interface ConfirmDeleteDialogState<T> {
  title: string;
  text: string;
  onConfirm: (value: T | undefined) => unknown;
  value: T;
}

interface ProductInfoSummaryProps {
  product: ProductInfoListResponse;
  sourceProduct?: ProductInfoListResponse;
  onRefresh: () => unknown;
  onUpdateProduct: (product: ProductInfoListResponse) => unknown;
  hideWidget?: boolean;
}

export function ProductInfoSummary({
  product,
  sourceProduct,
  onRefresh,
  onUpdateProduct,
  hideWidget
}: ProductInfoSummaryProps) {
  const { spot, raw, query, loading } = useSpot();
  const { t } = useTranslation();

  const [editingTag, setEditingTag] = useState<boolean>(false);
  const [confirmDelete, setConfirmDelete] =
    useState<ConfirmDeleteDialogState<string> | null>(null);
  const [confirmDeleteTm, setConfirmDeleteTm] =
    useState<ConfirmDeleteDialogState<{
      name: string;
      brandSlug: string;
      retailerSlug: string;
    }> | null>(null);
  useBrandLink();

  const originalProduct =
    sourceProduct ??
    spot.data.productInfos?.products?.find(
      p => p.productIdentifier === product.productIdentifier
    );

  const extendedProduct = useMemo(() => {
    if (!product.retailerSlug) {
      return null;
    }
    return spot.data.productInfo?.[product.retailerSlug]?.[
      product.productIdentifier
    ];
  }, [spot.data.productInfo, product]);

  const variants = useMemo(() => {
    return extendedProduct?.variants ?? [];
  }, [extendedProduct]);

  const [confirmExclude, setConfirmExclude] = useState<boolean>(false);
  const [showConnectProduct, setShowConnectProduct] = useState<boolean>(false);

  const { isAdmin } = useContext(RoleContext);
  const { retailer } = useContext(RetailerContext);

  const { displayErrors, displaySuccess, notification } =
    useErrorNotification();

  const handleSaveChanges = useCallback(async () => {
    const itemCsv = [['ProductId', 'Tag', 'Retailer', 'Brand'].join(',')]
      .concat(
        [
          product.productIdentifier,
          product.tagName,
          product.retailerSlug,
          product.brand
        ].join(',')
      )
      .join('\n');

    if (itemCsv) {
      try {
        await raw(`tag-metadata/csv?removeMissingTags=true`, {
          body: itemCsv,
          method: 'POST',
          headers: {
            'content-type': 'text/csv'
          }
        });
        await onRefresh();
        displaySuccess();
      } catch (e) {
        displayErrors(e as Error[]);
      }
    }
  }, [product, displayErrors, displaySuccess, raw, onRefresh]);

  const deleteTag = useCallback(() => {
    setConfirmDelete({
      text: t('deleteSizeConfirmationText'),
      title: t('deleteSizeConfirmation'),
      value: product.productIdentifier,
      onConfirm: async (value: string | undefined) => {
        setConfirmDelete(null);

        if (value) {
          onUpdateProduct({
            ...product,
            tagName: undefined,
            tagGender: undefined,
            tagProductName: undefined
          });
        }
      }
    });
  }, [setConfirmDelete, t, product, onUpdateProduct]);

  const changeTag = useCallback(() => {
    setEditingTag(true);
  }, [setEditingTag]);

  const confirmTagChange = useCallback(
    async (tag: Tag | null) => {
      onUpdateProduct({
        ...product,
        tagName: tag?.name,
        tagGender: tag?.gender,
        tagProductName: tag?.product?.name
      });
    },
    [onUpdateProduct, product]
  );

  const excludeTag = useCallback(() => {
    onUpdateProduct({
      ...product,
      tagName: 'Faslet_Excluded',
      tagGender: 'unisex',
      tagProductName: 'excluded'
    });
  }, [onUpdateProduct, product]);

  const excludeTagAndSave = useCallback(async () => {
    if (!product) {
      return;
    }
    product.tagName = 'Faslet_Excluded';
    await handleSaveChanges();
  }, [product, handleSaveChanges]);

  const tagAndSave = useCallback(
    async (value: string) => {
      if (!product) {
        return;
      }
      product.tagName = value;
      await handleSaveChanges();
    },
    [product, handleSaveChanges]
  );

  const cancelTagChange = useCallback(() => {
    setEditingTag(false);
  }, [setEditingTag]);

  const openProductPage = useCallback(() => {
    product?.productUrl && openInNewTab(product?.productUrl);
  }, [product]);

  const predictTag = useCallback(async () => {
    try {
      const prediction = await raw<{
        product?: string;
        gender?: 'male' | 'female' | 'unisex';
        labels?: string;
        tag?: string;
      }>('product-predictor/predict', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          retailer: product.retailerSlug,
          title: `${product.customLabel0 ? `[${product.customLabel0}] ` : ''}${
            product.name
          }`,
          gender: product.gender ?? product.tagGender,
          labels: variants.map(v => v.sizeLabel).join(','),
          brand: product.brand
        })
      });

      if (prediction) {
        onUpdateProduct({
          ...product,
          tagName: prediction.tag,
          tagGender: prediction.gender,
          tagProductName: prediction.product
        });
      }
    } catch (e) {
      displayErrors(e as Error[]);
    }
  }, [raw, product, onUpdateProduct, variants, displayErrors]);

  const handleSaveTtsSkew = useCallback(async () => {
    const itemCsv = [
      ['ProductId', 'Tag', 'Retailer', 'Brand', 'TtsSkew'].join(',')
    ]
      .concat(
        [
          product.productIdentifier,
          product.tagName,
          product.retailerSlug,
          product.brand,
          product.ttsSkew
        ].join(',')
      )
      .join('\n');

    if (itemCsv) {
      try {
        await raw(`tag-metadata/csv?removeMissingTags=true`, {
          body: itemCsv,
          method: 'POST',
          headers: {
            'content-type': 'text/csv'
          }
        });
        await onRefresh();
        displaySuccess();
      } catch (e) {
        displayErrors(e as Error[]);
      }
    }
  }, [product, displayErrors, displaySuccess, raw, onRefresh]);

  const onChangeTtsSkew = useCallback(
    (_, value) => {
      onUpdateProduct({
        ...product,
        ttsSkew: Number(value)
      });
    },
    [onUpdateProduct, product]
  );

  const deleteTagMetadata = useCallback(
    (name, brandSlug) => {
      if (!product.retailerSlug) {
        return;
      }
      setConfirmDeleteTm({
        text: t('deleteTagMetadataConfirmationText'),
        title: t('deleteTagMetadataConfirmation'),
        value: { name, brandSlug, retailerSlug: product.retailerSlug },
        onConfirm: async (
          value:
            | { name: string; brandSlug: string; retailerSlug: string }
            | undefined
        ) => {
          setConfirmDeleteTm(null);
          if (value) {
            try {
              await raw(
                `tag-metadata/${value.name}?retailer=${value.retailerSlug}&brand=${value.brandSlug}`,
                {
                  method: 'DELETE'
                }
              );

              await query(
                `product-info/${value.name}`,
                {
                  retailer: value.retailerSlug
                },
                ['productInfo', value.retailerSlug, value.name]
              );
              await onRefresh();
              displaySuccess();
            } catch (e) {
              displayErrors(e as Error[]);
            }
          }
        }
      });
    },
    [product, displayErrors, displaySuccess, raw, onRefresh, t, query]
  );

  const showConnectDialog = useCallback(() => {
    setShowConnectProduct(true);
  }, []);

  const showExcludeConfirmDialog = useCallback(() => {
    setConfirmExclude(true);
  }, []);

  return (
    <>
      <FormSectionTitle>
        <NameContainer>
          {isAdmin() && product.source === 'feed' && (
            <IconContainer title={t('sourceFeed')}>
              <FeedIcon />
            </IconContainer>
          )}
          {isAdmin() && product.source === 'widget' && (
            <IconContainer title={t('sourceWidget')}>
              <WidgetIcon />
            </IconContainer>
          )}
          {isAdmin() && product.source === 'size-chart-api' && (
            <IconContainer title={t('sourceSizeChartApi')}>
              <SizeChartUploadIcon />
            </IconContainer>
          )}
          {`${product.customLabel0 ? `[${product.customLabel0}] ` : ''}${
            product.name
          }`}
        </NameContainer>
      </FormSectionTitle>
      <ImageContainer onClick={openProductPage}>
        {product.imageLink && (
          <CroppedImage
            key={product.productIdentifier}
            width={200}
            height={200}
            url={product.imageLink}
          />
        )}
      </ImageContainer>
      <Gap />
      <FormSection>
        {product.brand && (
          <FormRow>
            <FormLabel size="narrow">{t('brand')}</FormLabel>
            <FormFieldWrapper>
              <BrandLink brand={product.brand} retailer={retailer} />
            </FormFieldWrapper>
          </FormRow>
        )}
        {(extendedProduct?.tagMetadatas?.length ?? 0) > 1 && isAdmin() && (
          <TagWarningSection>
            <Alert severity="error">{t('multipleTagMetadatasWarning')}</Alert>
            <Table
              headers={[t('name'), t('brand'), '']}
              rows={
                extendedProduct?.tagMetadatas.map(tagMetadata => ({
                  id: `${tagMetadata.id}`,
                  cells: [
                    tagMetadata.tagName,
                    tagMetadata.brandName,
                    <IconButton
                      key={`${tagMetadata.id}`}
                      onClick={() =>
                        deleteTagMetadata(
                          tagMetadata.name,
                          tagMetadata.brandSlug
                        )
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  ]
                })) ?? []
              }
            />
          </TagWarningSection>
        )}
        <FormRow alignment="baseline">
          <FormLabel size="narrow">{t('fasletStatus')}</FormLabel>
          <FormFieldWrapper>
            <SizeChartEditContainer>
              <SizeChartLink
                isAdmin={isAdmin}
                brand={product.brand}
                brandSlug={product.brandSlug}
                tagName={product.tagName}
                tagGender={product.tagGender}
                tagProductName={product.tagProductName}
                hasSizeChart={product.hasSizeChart}
                hasChartLabels={product.hasChartLabels}
              />
              {isAdmin() && (
                <ActionsContainer>
                  {(originalProduct?.tagName || product.tagName) &&
                    originalProduct?.tagName !== product.tagName && (
                      <IconButton
                        onClick={handleSaveChanges}
                        disabled={loading}
                      >
                        <SaveIcon />
                      </IconButton>
                    )}
                  <IconButton onClick={changeTag}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={predictTag}>
                    <SvgIcon component={PredictIcon} />
                  </IconButton>
                  {product.tagName !== 'Faslet_Excluded' && (
                    <IconButton onClick={excludeTag}>
                      <ExcludeIcon />
                    </IconButton>
                  )}
                  {product.tagName && (
                    <IconButton onClick={deleteTag}>
                      <UnlinkIcon />
                    </IconButton>
                  )}
                </ActionsContainer>
              )}
            </SizeChartEditContainer>
          </FormFieldWrapper>
        </FormRow>
        <FormRow>
          <FormLabel size="narrow">{t('productGender')}</FormLabel>
          <FormFieldWrapper>
            <Typography>
              {product.gender && t(product.gender.toLocaleLowerCase())}
            </Typography>
          </FormFieldWrapper>
        </FormRow>
        <FormRow>
          <FormLabel size="narrow">{t('productCategory')}</FormLabel>
          <FormFieldWrapper>
            <Typography>{product.productCategory}</Typography>
          </FormFieldWrapper>
        </FormRow>
        {product.brandSlug && (
          <CenteredRow>
            <Spacer />
            <Button
              onClick={showConnectDialog}
              variant="contained"
              color="primary"
              startIcon={<LinkIcon />}
            >
              {product.tagName ? t('reconnect') : t('connect')}
            </Button>
            <Gap size={1} />
            <Button
              onClick={showExcludeConfirmDialog}
              variant="contained"
              color="primary"
              startIcon={<ExcludeIcon />}
            >
              {t('exclude')}
            </Button>
          </CenteredRow>
        )}
        <Gap />
      </FormSection>
      {!hideWidget &&
        product.tagName &&
        product.tagName !== 'Faslet_Excluded' &&
        product.retailerSlug &&
        product.brand &&
        product.productIdentifier && (
          <WidgetSection>
            <FormRow>
              <Widget
                productId={product.productIdentifier}
                shopId={product.retailerSlug}
                brand={product.brand}
              />
            </FormRow>
          </WidgetSection>
        )}
      {(isAdmin() || retailer?.features?.ttsEditorInProductsScreen) && (
        <FormSection>
          <CenteredRow>
            <FormSectionTitle>{t('ttsSkew')}</FormSectionTitle>
            <Spacer />
            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveTtsSkew}
              startIcon={<SaveIcon />}
              disabled={loading || !product.tagName}
            >
              {t('save')}
            </Button>
          </CenteredRow>
          <TtsSkewInfo variant="subtitle2">{t('ttsSkewInfo')}</TtsSkewInfo>
          <FormRow>
            <Slider
              id="ttsSkew"
              value={product.ttsSkew ?? 0}
              onChange={onChangeTtsSkew}
              marks
              track={false}
              valueLabelDisplay={product.tagName ? 'on' : 'off'}
              step={0.1}
              min={-2}
              max={2}
              disabled={!product.tagName}
            />
          </FormRow>
        </FormSection>
      )}
      {editingTag && (
        <ChangeTagDialog
          dialogOpen={editingTag}
          text={t('editTagInfo')}
          title={t('editTag')}
          onSubmit={confirmTagChange}
          onClose={cancelTagChange}
          initialValue={product.tagName}
          brand={product.brand}
        />
      )}
      {confirmDelete?.onConfirm && (
        <ConfirmDeleteAlert
          id="confirmBrandDelete"
          title={t('deleteTagMetadataConfirmation')}
          text={t('deleteTagMetadataConfirmationText')}
          open={!!confirmDelete}
          onConfirm={confirmDelete?.onConfirm}
          value={confirmDelete?.value}
          keepMounted
        />
      )}
      {confirmDeleteTm?.onConfirm && (
        <ConfirmDeleteAlert
          id="confirmTagMetadataDelete"
          title={t('deleteTagMetadataConfirmation')}
          text={t('deleteTagMetadataConfirmationText')}
          open={!!confirmDeleteTm}
          onConfirm={confirmDeleteTm?.onConfirm}
          value={confirmDeleteTm?.value}
          keepMounted
        />
      )}
      {confirmExclude && (
        <ConfirmDialog
          dialogOpen={confirmExclude}
          text={t('excludeConfirmationText')}
          title={t('excludeConfirmation')}
          onClose={() => setConfirmExclude(false)}
          onSubmit={excludeTagAndSave}
        />
      )}
      {showConnectProduct && (
        <ConnectProductDialog
          dialogOpen={showConnectProduct}
          onClose={() => setShowConnectProduct(false)}
          onSubmit={tagAndSave}
          productGender={
            product.gender &&
            ['male', 'female', 'unisex'].includes(product.gender)
              ? (product.gender as ProductGender)
              : undefined
          }
        />
      )}
      {notification}
    </>
  );
}
