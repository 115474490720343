import React, { useCallback, useContext, useState } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { BrandSelector, CenteredRow, Spacer, Tabs } from '../../components';
import { UntaggedProducts } from './untagged-products';
import { Dictionary } from './dictionary';
import { IgnoreList } from './ignore-list';
import { Brand, RetailerContext } from '../../framework';

export function Untagged() {
  const { t } = useTranslation();
  const { retailer } = useContext(RetailerContext);

  const navigate = useNavigate();
  const { tab } = useParams<{ tab: string }>();

  const [loading, setLoading] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState<Brand | null>(null);

  const onChangeTab = useCallback(
    (newTab: string) => {
      navigate(`/untagged-product/${newTab}`);
    },
    [navigate]
  );

  const brandDisabled =
    ((tab === 'productDictionary' || tab === 'genderDictionary') &&
      !!retailer) ||
    tab === 'ignoreList';

  const resolvedBrand = brandDisabled ? null : selectedBrand;

  if (tab === 'storeProducts') {
    navigate('/products');
  }

  return (
    <>
      <CenteredRow>
        <Typography variant="h4">{t('untaggedProducts')}</Typography>
        <Spacer />
        <BrandSelector
          selectedBrand={resolvedBrand}
          includeAllBrands
          onBrandChanged={setSelectedBrand}
          disabled={brandDisabled}
        />
      </CenteredRow>
      <Tabs
        selected={tab ?? 'products'}
        onTabChange={onChangeTab}
        hideIfSingle
        loading={loading}
        tabs={{
          products: (
            <UntaggedProducts
              onLoadingChanged={setLoading}
              selectedBrand={resolvedBrand}
            />
          ),
          productDictionary: (
            <Dictionary
              onLoadingChanged={setLoading}
              type="product"
              selectedBrand={resolvedBrand}
            />
          ),
          genderDictionary: (
            <Dictionary
              onLoadingChanged={setLoading}
              type="gender"
              selectedBrand={resolvedBrand}
            />
          ),
          ignoreList: <IgnoreList onLoadingChanged={setLoading} />
        }}
      />
    </>
  );
}
