import styled from '@emotion/styled';
import {
  Box,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField
} from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import moment from 'moment/moment';
import { DatePicker } from '@mui/x-date-pickers';
import { useTranslation } from 'react-i18next';

import { Gap } from './spacer';

export type dateRangeOption =
  | 'lastSevenDays'
  | 'lastThirtyDays'
  | 'lastMonth'
  | 'thisMonth'
  | 'lastYear'
  | 'thisYear'
  | 'last12Months'
  | 'allTime'
  | 'custom'
  | 'twoMonthsAgo';

const defaultRanges: dateRangeOption[] = [
  'lastSevenDays',
  'lastThirtyDays',
  'lastMonth',
  'twoMonthsAgo',
  'thisMonth',
  'lastYear',
  'thisYear',
  'custom'
];

const SelectBox = styled(Box)`
  width: 100%;
  position: relative;
`;

const DatePickerBox = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-top: ${p => p.theme.spacing(2)};
`;

const FilterBox = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 0 1 300px;
  align-items: flex-end;
`;

export interface DateBoxProps {
  disabled?: boolean;
  onStartChange: (startDate: moment.Moment) => unknown;
  onEndChange: (endDate: moment.Moment) => unknown;
  allowedRanges?: dateRangeOption[];
  defaultRange?: dateRangeOption;
  includeToday?: boolean;
}

const getStartDateFromRange = (range: dateRangeOption) => {
  if (range === 'lastSevenDays') {
    return moment().subtract(7, 'day').startOf('day');
  }
  if (range === 'lastThirtyDays') {
    return moment().subtract(30, 'day').startOf('day');
  }
  if (range === 'lastMonth') {
    return moment().subtract(1, 'month').startOf('month');
  }
  if (range === 'twoMonthsAgo') {
    return moment().subtract(2, 'month').startOf('month');
  }
  if (range === 'thisMonth') {
    return moment().startOf('month').startOf('day');
  }
  if (range === 'lastYear') {
    return moment().subtract(1, 'year').startOf('year');
  }
  if (range === 'thisYear') {
    return moment().startOf('year').startOf('day');
  }
  if (range === 'last12Months') {
    return moment().subtract(12, 'month').startOf('month');
  }
  if (range === 'allTime') {
    return moment('2022-01-01', 'YYYY-MM-DD');
  }
  return null;
};

const getEndDateFromRange = (
  range: dateRangeOption,
  includeToday?: boolean
) => {
  if (range === 'lastSevenDays') {
    return moment()
      .subtract(includeToday ? 0 : 1, 'day')
      .endOf('day');
  }
  if (range === 'lastThirtyDays') {
    return moment()
      .subtract(includeToday ? 0 : 1, 'day')
      .endOf('day');
  }
  if (range === 'lastMonth') {
    return moment().subtract(1, 'month').endOf('month');
  }
  if (range === 'twoMonthsAgo') {
    return moment().subtract(2, 'month').endOf('month');
  }
  if (range === 'thisMonth') {
    return moment()
      .subtract(includeToday ? 0 : 1, 'day')
      .endOf('day');
  }
  if (range === 'lastYear') {
    return moment().subtract(1, 'year').endOf('year');
  }
  if (range === 'thisYear') {
    return moment()
      .subtract(includeToday ? 0 : 1, 'day')
      .endOf('day');
  }
  if (range === 'last12Months') {
    return moment()
      .subtract(includeToday ? 0 : 1, 'day')
      .endOf('day');
  }
  if (range === 'allTime') {
    return moment()
      .subtract(includeToday ? 0 : 1, 'day')
      .endOf('day');
  }
  return null;
};

export function DateBox({
  disabled,
  onStartChange,
  onEndChange,
  allowedRanges,
  defaultRange,
  includeToday
}: DateBoxProps) {
  const { t } = useTranslation();

  const [customCalendar, setCustomCalendar] = useState<boolean>(false);

  const maxRangeInMonths = 12;
  const allowedDateRanges = useRef(allowedRanges ?? [...defaultRanges]);

  const [currentDate, setCurrentDate] = useState<dateRangeOption>(
    defaultRange ?? 'lastMonth'
  );
  const [startDate, setStartDate] = useState<moment.Moment>(
    getStartDateFromRange(defaultRange ?? 'lastMonth') ??
      moment().subtract(1, 'month').startOf('month')
  );
  const [endDate, setEndDate] = useState<moment.Moment>(
    getEndDateFromRange(defaultRange ?? 'lastMonth', includeToday) ??
      moment().startOf('month')
  );

  const onEndDateChange = useCallback(
    v => {
      setEndDate(v);

      if (v && startDate && v.diff(startDate, 'months') >= maxRangeInMonths) {
        setStartDate(moment(v).subtract(maxRangeInMonths, 'months'));
      }
    },
    [setEndDate, setStartDate, startDate, maxRangeInMonths]
  );

  const onStartDateChange = useCallback(
    v => {
      setStartDate(v);

      if (v && endDate && endDate.diff(v, 'months') >= maxRangeInMonths) {
        setEndDate(moment(v).add(maxRangeInMonths, 'months'));
      }
    },
    [setEndDate, setStartDate, endDate, maxRangeInMonths]
  );

  useEffect(() => {
    onStartChange(startDate);
  }, [startDate, onStartChange]);

  useEffect(() => {
    onEndChange(endDate);
  }, [endDate, onEndChange]);

  const onFancyDateSelect = useCallback(
    (event: SelectChangeEvent<dateRangeOption>) => {
      const targetRange = event.target.value as dateRangeOption;
      if (allowedDateRanges.current.indexOf(targetRange) === -1) {
        return;
      }

      const newStartDate = getStartDateFromRange(targetRange);
      if (newStartDate) {
        setStartDate(newStartDate);
      }

      const newEndDate = getEndDateFromRange(targetRange, includeToday);
      if (newEndDate) {
        setEndDate(newEndDate);
      }

      setCurrentDate(targetRange);
      setCustomCalendar(targetRange === 'custom');
    },
    [setCustomCalendar, setCurrentDate, allowedDateRanges, includeToday]
  );

  return (
    <FilterBox>
      <SelectBox>
        <Select
          disabled={disabled}
          fullWidth
          value={currentDate ?? ''}
          onChange={onFancyDateSelect}
        >
          {allowedDateRanges.current.map(el => (
            <MenuItem key={el} value={el}>
              {t(el)}
            </MenuItem>
          ))}
        </Select>
        {customCalendar ? (
          <DatePickerBox>
            <DatePicker
              disabled={disabled}
              label={t('startDate')}
              value={startDate ? moment(startDate, 'DD-MM') : null}
              onChange={onStartDateChange}
              renderInput={params => (
                <TextField {...params} error={!startDate} />
              )}
              minDate={moment('2022-01-01T00:00:00.000Z')}
              maxDate={endDate}
              inputFormat="D MMM YYYY"
              views={['month', 'day']}
              disableMaskedInput
            />
            <Gap />
            <DatePicker
              disabled={disabled}
              label={t('endDate')}
              value={endDate ? moment(endDate, 'DD-MM') : null}
              onChange={onEndDateChange}
              renderInput={params => <TextField {...params} error={!endDate} />}
              minDate={startDate}
              maxDate={moment().endOf('day')}
              inputFormat="D MMM YYYY"
              views={['month', 'day']}
              disableMaskedInput
            />
          </DatePickerBox>
        ) : null}
      </SelectBox>
    </FilterBox>
  );
}
