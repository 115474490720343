import React, { useState, useCallback, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  AppBar,
  Toolbar,
  Box,
  ButtonBase,
  Hidden,
  Button,
  Menu,
  MenuItem,
  Avatar,
  Popover,
  IconButton,
  CircularProgress,
  ListItemIcon,
  ListItemText,
  Badge,
  Select,
  SelectChangeEvent,
  Divider
} from '@mui/material';
import styled from '@emotion/styled';
import {
  CheckCircle as IntegrationCorrectIcon,
  WarningRounded as IntegrationWarningIcon,
  CloudOffRounded as IntegrationCheckFailedIcon,
  NotificationsRounded as NotificationIcon,
  InfoRounded as NotificationInfoIcon,
  ErrorRounded as NotificationErrorIcon,
  WarningRounded as NotificationWarningIcon
} from '@mui/icons-material';
import moment from 'moment';

import {
  useSpot,
  logout,
  RetailerContext,
  Retailer,
  RoleContext,
  openInNewTab
} from 'framework';
import {
  Gap,
  RetailerSelector,
  Spacer,
  WidgetIntegrationStatus
} from 'components';

import logoWide from '../images/logo-full-white.png';

const StyledToolbar = styled(Toolbar)`
  display: flex;
`;

const StyledCompanyName = styled(ButtonBase)``;

const NotificationText = styled(ListItemText)<{ read: boolean }>`
  * {
    color: ${p => (p.read ? '#999' : '#222')};
    white-space: normal;
  }
`;

const NotificationBadge = styled(Badge)<{
  hasNotifications: boolean;
}>`
  animation: ${p =>
    p.hasNotifications
      ? 'shake 3s cubic-bezier(0.36, 0.07, 0.19, 0.97) 10 both'
      : 'unset'};
`;

const NotificationsMenu = styled(Menu)``;

const NotificationsMenuItem = styled(MenuItem)<{ read: boolean }>`
  width: 400px;
  border-bottom: 1px solid #eee;
`;

const SelectWrapper = styled(MenuItem)`
  width: 240px;
  display: flex;
  align-items: center;

  .MuiInputBase-root {
    background-color: white;
    height: 44px;
    border-radius: 5px;
  }
`;

export function Header() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data, command, query } = useSpot();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [notificationAnchorEl, setNotificationAnchorEl] =
    useState<null | HTMLElement>(null);

  const { isAdmin, role, onRoleChanged } = useContext(RoleContext);

  const { retailer, onRetailerChanged } = useContext(RetailerContext);

  const handleUserMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleUserMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleNotificationsClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setNotificationAnchorEl(event.currentTarget);
  };

  const handleNotificationsClose = useCallback(() => {
    setNotificationAnchorEl(null);
  }, [setNotificationAnchorEl]);

  const handleLogout = useCallback(() => {
    logout();
    handleUserMenuClose();
  }, [handleUserMenuClose]);

  const userAccount = () => {
    navigate(`/user-account`);
    handleUserMenuClose();
  };

  const roleChanged = useCallback(
    (event: SelectChangeEvent<string | undefined>) => {
      if (!event.target.value) {
        return;
      }
      onRoleChanged(event.target.value);
    },
    [onRoleChanged]
  );

  const notificationAction = useCallback(
    async (id: number, action: string, actionInfo: string) => {
      await command(`profile/notifications/${id}/read`);
      await query('profile/notifications', {}, ['notifications']);
      if (action === 'screen') {
        navigate(actionInfo);
      } else if (action === 'link') {
        openInNewTab(actionInfo);
      }
      handleNotificationsClose();
    },
    [command, navigate, query, handleNotificationsClose]
  );

  useEffect(() => {
    query('profile/notifications', {}, ['notifications']);
    const interval = setInterval(() => {
      query('profile/notifications', {}, ['notifications']);
    }, 60000);
    return () => clearInterval(interval);
  }, [query]);

  const timeDesc = (a, b) => {
    return moment(b.createdAt).diff(moment(a.createdAt), 'seconds');
  };

  const unreadCount = data.notifications?.filter(n => !n.read)?.length ?? 0;

  const relevantNotifications =
    data.notifications
      ?.filter(
        n =>
          !n.read || moment(n.updatedAt).isAfter(moment().subtract(3, 'week'))
      )
      ?.sort((a, b) => {
        if (a.read === b.read) {
          return timeDesc(a, b);
        }
        return a.read && !b.read ? 1 : -1;
      })
      ?.slice(0, 10) ?? [];

  return (
    <AppBar position="fixed">
      <StyledToolbar variant="dense">
        <Hidden mdDown>
          <StyledCompanyName onClick={() => navigate('/')}>
            <img src={logoWide} alt={t('logo')} height={48} />
          </StyledCompanyName>
          <Spacer />
        </Hidden>
        {retailer && (
          <>
            <Gap />
            <WidgetIntegrationPopup retailer={retailer} />
            <Gap />
          </>
        )}
        <RetailerSelector
          includeAllRetailers
          selectedRetailer={retailer}
          onRetailerChanged={onRetailerChanged}
        />
        <Hidden mdUp>
          <Spacer />
        </Hidden>
        <Hidden mdDown>
          <Gap />
        </Hidden>
        <IconButton onClick={handleNotificationsClick}>
          <NotificationBadge
            badgeContent={unreadCount}
            color="header"
            variant="dot"
            hasNotifications={!!unreadCount}
          >
            <NotificationIcon color={unreadCount ? 'header' : 'inherit'} />
          </NotificationBadge>
        </IconButton>
        <NotificationsMenu
          id="notifications-menu"
          anchorEl={notificationAnchorEl}
          keepMounted
          open={Boolean(notificationAnchorEl)}
          onClose={handleNotificationsClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          {relevantNotifications.length ? (
            relevantNotifications.map(n => (
              <NotificationsMenuItem
                key={n.id}
                onClick={() => notificationAction(n.id, n.action, n.actionInfo)}
                read={n.read}
              >
                <ListItemIcon>
                  {n.level === 'info' && <NotificationInfoIcon />}
                  {n.level === 'warn' && <NotificationWarningIcon />}
                  {n.level === 'error' && <NotificationErrorIcon />}
                </ListItemIcon>
                <NotificationText read={n.read}>
                  <Typography variant="subtitle1">{n.title}</Typography>
                  <Typography variant="body2">{n.message}</Typography>
                </NotificationText>
              </NotificationsMenuItem>
            ))
          ) : (
            <MenuItem onClick={handleNotificationsClose}>
              <ListItemText>{t('noNotifications')}</ListItemText>
            </MenuItem>
          )}
        </NotificationsMenu>
        <Button
          aria-controls="user-menu-button"
          aria-haspopup="true"
          onClick={handleUserMenuClick}
        >
          <Avatar src={data.profile?.avatar ?? ''}>
            {`${data.profile?.firstName[0] ?? ''}${
              data.profile?.lastName[0] ?? ''
            }`}
          </Avatar>
        </Button>
        <Menu
          id="user-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleUserMenuClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          {data?.profile?.role === 'admin' && (
            <Hidden smDown>
              <MenuItem>{t('viewAs')}</MenuItem>
              <SelectWrapper>
                <Select
                  value={role ?? undefined}
                  onChange={roleChanged}
                  fullWidth
                >
                  <MenuItem value="admin">{t('admin')}</MenuItem>
                  <MenuItem value="user">{t('user')}</MenuItem>
                  <MenuItem value="analytics-admin">
                    {t('analytics-admin')}
                  </MenuItem>
                </Select>
              </SelectWrapper>
              <Divider />
            </Hidden>
          )}
          <MenuItem onClick={userAccount}>
            {t('userAccountAndBilling')}
          </MenuItem>
          <MenuItem onClick={handleLogout}>{t('logout')}</MenuItem>
        </Menu>
      </StyledToolbar>
    </AppBar>
  );
}

const IntegrationPopup = styled(Box)`
  max-width: 380px;
`;

const IntegrationButton = styled(IconButton)`
  color: #ffffff;
`;

const ErrorIcon = styled(IntegrationWarningIcon)`
  animation: shake 3s cubic-bezier(0.36, 0.07, 0.19, 0.97) 10 both;
  color: #ffcd00;
`;

function WidgetIntegrationPopup({ retailer }: { retailer: Retailer }) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { query, spot } = useSpot();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        await query(
          'analytics/integration-status',
          {
            retailers: [
              encodeURIComponent(retailer.name),
              encodeURIComponent(retailer.slug)
            ]
          },
          ['analytics', 'integrationStatus', retailer.name]
        );
      } catch (e) {
        console.error(e);
      }
      setLoading(false);
    })();
  }, [query, retailer.name, retailer.slug, setLoading]);

  const retailerStatus =
    spot.data.analytics?.integrationStatus?.[retailer.name];
  const status =
    retailerStatus?.hasButtonShow &&
    retailerStatus?.hasAddToCart &&
    retailerStatus?.hasVariants &&
    retailerStatus?.hasOrderTracking &&
    retailerStatus?.hasOrderSku &&
    retailerStatus?.hasOrderCorrelationId &&
    retailerStatus?.hasOrdersWhereAdviceFollowed;

  const onClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IntegrationButton
        onClick={onClick}
        disabled={!retailerStatus}
        title={t('integrationWarning')}
      >
        {loading && <CircularProgress size={24} color="inherit" />}
        {!loading && retailerStatus && status && <IntegrationCorrectIcon />}
        {!loading && retailerStatus && !status && <ErrorIcon />}
        {!loading && !retailerStatus && <IntegrationCheckFailedIcon />}
      </IntegrationButton>
      <Popover
        open={!!anchorEl}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <IntegrationPopup>
          <WidgetIntegrationStatus
            retailer={retailer}
            integrationStatus={retailerStatus}
            loading={loading}
          />
        </IntegrationPopup>
      </Popover>
    </>
  );
}
